<template>
  <v-snackbar
    v-if="show"
    :value="show"
    :color="color"
    :timeout="timeout"
    class="notification"
    app
    outlined
    top
    center
    text
    elevation="24"
  >
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-icon
        :color="color"
        v-bind="attrs"
        @click="remove"
      >mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  methods: {
    remove() {
      this.$store.commit('application/resetNotification');
    },
  },
  computed: {
    ...mapGetters('application', [
      'notification',
      'colors',
    ]),
    message() {
      return this.notification?.message;
    },
    color() {
      return this.colors[this.notification.type];
    },
    show() {
      return !!this.notification;
    },
    timeout() {
      return this.notification.timeout;
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  margin-bottom: 70px;
  z-index: 100;
}
</style>
